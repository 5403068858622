import {
  useEffect,
  useContext,
  useState,
  ReactNode,
  createContext,
} from "react";
import { DemoConfig } from "../App";

interface ConfigContextType {
  isConfigLoaded: boolean;
  demoConfig: DemoConfig;
}

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

type ConfigContextProviderProps = {
  children: ReactNode;
};

export const ConfigContextProvider = ({
  children,
}: ConfigContextProviderProps) => {
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [demoConfig, setDemoConfig] = useState<DemoConfig>({
    appTitle: "",
    enableAppHeader: true,
    appHeaderLogoLink: "",
    appHeaderLogoSrc: "",
    appHeaderLogoAlt: "",
    appHeaderLogoHeight: 20,
    iframeSrc: "",
    imageSrc: "",
    authenticate: false,
    googleClientId: "",
    apiUrl: "",
    customerId: "",
    apiKey: "",
    corpusKeys: "",
    chatTitle: "",
    chatBoxSize: "large",
    chatPlaceHolder: "",
    chatDefaultOpen: true,
    enableStreaming: false,
    summaryPromptName: "",
    exampleQuestion: "",
    lambda: 0.005,
    rerankerId: 272725718,
    numberOfSearchResults: 10
  });

  useEffect(() => {
    if (isConfigLoaded) return;
    const loadConfig = async () => {
      const res = await fetch("/config");
      const config = (await res.json()) as DemoConfig;

      console.log(config)

      setIsConfigLoaded(true);

      setDemoConfig({
        appTitle: config.appTitle ?? "Vectara Chat",
        enableAppHeader: config.enableAppHeader ?? true,
        appHeaderLogoLink: config.appHeaderLogoLink,
        appHeaderLogoSrc: config.appHeaderLogoSrc,
        appHeaderLogoAlt: config.appHeaderLogoAlt,
        appHeaderLogoHeight: config.appHeaderLogoHeight,
        iframeSrc: config.iframeSrc,
        imageSrc: config.imageSrc,
        authenticate: config.authenticate,
        googleClientId: config.googleClientId,
        apiUrl: config.apiUrl ?? "http://localhost:4400",
        customerId: config.customerId,
        apiKey: config.apiKey,
        corpusKeys: config.corpusKeys,
        chatTitle: config.chatTitle,
        chatPlaceHolder: config.chatPlaceHolder,
        chatBoxSize: config.chatBoxSize,
        chatDefaultOpen: config.chatDefaultOpen,
        enableStreaming: config.enableStreaming,
        summaryPromptName: config.summaryPromptName,
        exampleQuestion: config.exampleQuestion,
        rerankerId: config.rerankerId,
        lambda: config.lambda,
        numberOfSearchResults: config.numberOfSearchResults
      });
    };

    loadConfig();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        isConfigLoaded,
        demoConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error(
      "useConfigContext must be used within a ConfigContextProvider"
    );
  }
  return context;
};

import { useEffect } from "react";
import "./App.scss";
import {
  AuthenticationContextProvider,
  useAuthenticationContext,
} from "./contexts/AuthenticationContext";
import { LoggedOutView } from "./views/loggedOut/LoggedOutView";
import {
  ConfigContextProvider,
  useConfigContext,
} from "./contexts/ConfigurationContext";
import { DemoView } from "./views/DemoView";
import { BrowserRouter } from "react-router-dom";

export type DemoConfig = {
  appTitle: string | undefined;
  enableAppHeader: boolean;
  appHeaderLogoLink: string | undefined;
  appHeaderLogoSrc: string | undefined;
  appHeaderLogoAlt: string | undefined;
  appHeaderLogoHeight: number | null;
  iframeSrc: string | undefined;
  imageSrc: string | undefined;
  authenticate: boolean;
  googleClientId: string | undefined;
  apiUrl: string | undefined;
  customerId: string;
  apiKey: string;
  corpusKeys: string
  chatTitle: string
  chatPlaceHolder: string
  chatBoxSize: "large" | "medium" | undefined
  chatDefaultOpen: boolean
  enableStreaming: boolean
  summaryPromptName: string
  exampleQuestion: string
  lambda?: number,
  rerankerId?: number
  numberOfSearchResults?: number

};

export const AppRoutes = () => {
  const { isConfigLoaded, demoConfig } = useConfigContext();
  const { isAuthEnabled, isAuthenticated, logIn } = useAuthenticationContext();

  useEffect(() => {
    if (isAuthEnabled) {
      const authToken = localStorage.getItem("AuthToken");
      logIn(authToken);
    }

    if (demoConfig.appTitle) document.title = demoConfig.appTitle;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigLoaded]);

  if (!isAuthenticated) {
    return <LoggedOutView />;
  }

  return <DemoView />;
};

export const App = () => {
  return (
    <BrowserRouter>
      <ConfigContextProvider>
        <AuthenticationContextProvider>
          <AppRoutes />
        </AuthenticationContextProvider>
      </ConfigContextProvider>
    </BrowserRouter>
  );
};

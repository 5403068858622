import { GoogleLogin } from "@react-oauth/google";
import { useAuthenticationContext } from "../../contexts/AuthenticationContext";
import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiText,
  VuiTextColor,
  VuiTitle,
} from "../../ui";
import "./loggedOutView.scss";
import { useConfigContext } from "../../contexts/ConfigurationContext";

export const LoggedOutView = () => {
  const { demoConfig } = useConfigContext();
  const { appHeaderLogoSrc, appHeaderLogoAlt, appHeaderLogoHeight, appTitle } =
    demoConfig;
  const { logIn } = useAuthenticationContext();

  return (
    <VuiFlexContainer
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing="l"
      className="loggedOutView"
    >
      {appHeaderLogoSrc && (
        <VuiFlexItem>
          <img
            src={appHeaderLogoSrc}
            alt={appHeaderLogoAlt}
            height={appHeaderLogoHeight?.toString()}
          />
        </VuiFlexItem>
      )}

      {appTitle && (
        <VuiFlexItem grow={false}>
          <VuiTitle size="m">
            <h2>
              <strong>{appTitle}</strong>
            </h2>
          </VuiTitle>
        </VuiFlexItem>
      )}

      <VuiFlexItem>
        <VuiText align="center">
          <VuiTextColor color="subdued">Log in with Google</VuiTextColor>
        </VuiText>
      </VuiFlexItem>

      <VuiFlexItem>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            logIn(credentialResponse.credential);
          }}
          onError={() => {
            console.error("Login Failed");
          }}
        />
      </VuiFlexItem>
    </VuiFlexContainer>
  );
};

import classNames from "classnames";
import { AppHeader } from "../components/AppHeader";
import { useConfigContext } from "../contexts/ConfigurationContext";
import { VuiFlexContainer, VuiSpinner, VuiSpacer, VuiTitle } from "../ui";
import {ReactChatbot} from "@vectara/react-chatbot";

export const DemoView = () => {
  const { isConfigLoaded, demoConfig } = useConfigContext();

  let content;

  if (!isConfigLoaded) {
    content = (
      <>
        <VuiFlexContainer
          className="appSpinner"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing="none"
        >
          <VuiSpinner size="l" />
          <VuiSpacer size="l" />
          <VuiTitle size="xs">
            <h2>Loading</h2>
          </VuiTitle>
        </VuiFlexContainer>
      </>
    );
  } else {
    const classes = classNames("embeddedWebsite", {
      "embeddedWebsite--withHeader": demoConfig.enableAppHeader,
    });

    content = (
      <>
        {demoConfig.enableAppHeader && <AppHeader />}

        {demoConfig.imageSrc ? (
          <div className={classes}>
            <img
              className="embeddedWebsite__image"
              src={`images/${demoConfig.imageSrc}`}
              alt="A screenshot of the embedded website"
            />
          </div>
        ) : demoConfig.iframeSrc ? (
          <iframe
            className={classes}
            src={demoConfig.iframeSrc}
            title="Background"
            allowFullScreen
          />
        ) : (
          <div>No source defined</div>
        )}
      </>
    );
  }
  return (
    <>
      {content}
        <ReactChatbot
            customerId={demoConfig.customerId}
            corpusKeys={demoConfig.corpusKeys}
            apiKey={demoConfig.apiKey}
            title={demoConfig.chatTitle}
            placeholder={demoConfig.chatPlaceHolder}
            inputSize={demoConfig.chatBoxSize}
            isInitiallyOpen={demoConfig.chatDefaultOpen}
            enableStreaming={demoConfig.enableStreaming}
            summaryPromptName={demoConfig.summaryPromptName}
            exampleQuestions={demoConfig.exampleQuestion.split(',')}
            lambda={demoConfig.lambda}
            rerankerId={demoConfig.rerankerId}
            numberOfSearchResults={demoConfig.numberOfSearchResults}
        />;
    </>
  );
};
